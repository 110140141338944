import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FIELDS_LIST } from '@modules/booking/customerForm/queries';

import CategorySelector from '@common/category/CategorySelector';
import { useCategoryContext } from '@common/context/CategoryContext';
import { getSessionId } from '@common/utils/session';
import { User } from '@components/User/User';
import { FieldsListQuery } from '@typings/api';

import {
  ActionType,
  useBookingContext,
} from '../../_common/context/BookingContext';
import useFeatureFlag from '../../_common/utils/hooks/useFeatureFlag';
import {
  AuthMethod,
  Flow,
  getDecodedToken,
  logout,
} from '../../_common/utils/token';
import { BOOKING_PATH, SELF_SERVICE_PATH } from '../../utils/constants';

import { InstanceLogo } from './InstanceLogo';

export function InstanceHeader() {
  const { t } = useTranslation();
  const sessionId = getSessionId();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [{ categoryId, flow: bookingFlow }, dispatch] = useBookingContext();
  const { categories, categorySelectionEnabled } = useCategoryContext();
  const isMaintenanceMode = useFeatureFlag('maintenance.enabled');

  const { accessLevel, firstName, id, lastName, method } =
    getDecodedToken() ?? {};

  const currentFlowPath =
    bookingFlow === Flow.SELF_SERVICE || pathname.includes(SELF_SERVICE_PATH)
      ? SELF_SERVICE_PATH
      : BOOKING_PATH;

  const showCategorySelector =
    categorySelectionEnabled &&
    categoryId != null &&
    categories.length > 1 &&
    !isMaintenanceMode;

  const showStopButton =
    (pathname === SELF_SERVICE_PATH || method === AuthMethod.CONTEXT) &&
    categoryId != null;

  const { data: fieldsListData } = useQuery<FieldsListQuery>(FIELDS_LIST, {
    variables: { categoryId, active: true },
    skip: categoryId == null,
  });
  const redirectUrl =
    (fieldsListData?.category.fields ?? []).find(
      (field) => field.key === 'redirectUrl',
    )?.defaultValue ?? undefined;

  async function handleLogout() {
    let redirectPath = '/login';

    if (showStopButton) {
      if (redirectUrl !== undefined) {
        return (window.location.href = redirectUrl);
      }

      redirectPath = currentFlowPath;
    }

    dispatch({ type: ActionType.RESET_INITIAL_STATE });

    await logout();
    await history.push(redirectPath);

    return;
  }

  function handleRestartCategory() {
    dispatch({
      type: ActionType.RESET_INITIAL_STATE,
    });

    return history.push({
      pathname: currentFlowPath,
      search: categoryId ? `?categoryId=${categoryId}` : undefined,
    });
  }

  function handleNavigateStart() {
    dispatch({
      type: ActionType.RESET_INITIAL_STATE,
    });

    if (categorySelectionEnabled && categories.length > 0) {
      return history.push({
        pathname: currentFlowPath,
      });
    }

    return history.push({
      pathname: currentFlowPath,
      search: categoryId ? `?categoryId=${categoryId}` : undefined,
    });
  }

  return (
    <div className="relative flex flex-wrap items-center justify-between w-full px-3 py-2 space-x-0 bg-white border-b border-gray-300 min-h-16 md:space-x-4 lg:space-x-8">
      <div className="w-1/2 md:w-auto xl:w-1/3">
        <InstanceLogo onClick={handleNavigateStart} />
      </div>
      {showCategorySelector && (
        <Route path={[BOOKING_PATH, SELF_SERVICE_PATH]}>
          <div className="flex order-last w-full mt-4 md:flex-1 md:order-2 md:mt-0 xl:w-1/3">
            <div className="flex w-full md:mx-auto md:max-w-xl">
              <div className="flex-1">
                <CategorySelector
                  label={t('translation:select.category')}
                  name={t('translation:select.category')}
                />
              </div>
              {currentFlowPath.includes(BOOKING_PATH) && (
                <RestartButton
                  onClick={handleRestartCategory}
                  title={t('translation:select.restart')}
                />
              )}
            </div>
          </div>
        </Route>
      )}
      <div className="flex justify-end order-3 w-1/2 ml-auto space-x-4 md:ml-0 md:w-auto xl:w-1/3">
        {id !== undefined && (
          <>
            {method !== AuthMethod.CONTEXT && (
              <div title={`Id: ${id}\nSession: ${sessionId}`}>
                <User
                  firstName={firstName ?? ''}
                  lastName={lastName ?? ''}
                  onClick={() => history.push(`/user${search}`)}
                />
              </div>
            )}
            {accessLevel !== undefined && accessLevel <= 2 && (
              <Switch>
                <Route path={[BOOKING_PATH, SELF_SERVICE_PATH, '/error']}>
                  <div className="hidden md:flex md:justify-center">
                    <Link
                      className="btn btn-tertiary text-brand-400"
                      to={{ pathname: '/admin', search }}
                    >
                      {t('instance_settings_button')}
                    </Link>
                  </div>
                  <div className="flex justify-center md:hidden">
                    <Link
                      className="btn text-brand-400"
                      to={{ pathname: 'admin', search }}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'user-shield']}
                        size="lg"
                      />
                    </Link>
                  </div>
                </Route>
              </Switch>
            )}
            <Route path={['/user', '/admin']}>
              <div className="hidden md:flex md:justify-center">
                <Link
                  className="btn btn-tertiary text-brand-400"
                  to={{ pathname: currentFlowPath, search }}
                >
                  {t('go_to_booking_button')}
                </Link>
              </div>
              <div className="flex justify-center md:hidden">
                <Link
                  className="btn text-brand-400"
                  to={{ pathname: currentFlowPath, search }}
                >
                  <FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" />
                </Link>
              </div>
            </Route>
          </>
        )}
        {(id !== undefined || showStopButton) && (
          <>
            <div className="hidden md:flex md:justify-center">
              <button
                className="btn btn-tertiary text-brand-400"
                onClick={handleLogout}
              >
                {showStopButton ? t('common.stop') : t('common.logout')}
              </button>
            </div>
            <div className="flex justify-center md:hidden">
              <button className="btn text-brand-400" onClick={handleLogout}>
                <FontAwesomeIcon icon={['fas', 'power-off']} size="lg" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function RestartButton({
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  // <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->

  return (
    <button
      className="p-2 text-gray-300 transition-colors duration-100 transform border border-l-0 border-gray-300 group hover:text-gray-700 focus:text-gray-700"
      {...props}
    >
      <svg
        className="w-5 h-5 transition-transform duration-200 transform group-hover:-rotate-12"
        fill="currentColor"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.86 0-95.19-15.58-134.2-44.86c-14.14-10.59-17-30.66-6.391-44.81c10.61-14.09 30.69-16.97 44.8-6.375c27.84 20.91 61 31.94 95.89 31.94C344.3 415.8 416 344.1 416 256s-71.67-159.8-159.8-159.8C205.9 96.22 158.6 120.3 128.6 160H192c17.67 0 32 14.31 32 32S209.7 224 192 224H48c-17.67 0-32-14.31-32-32V48c0-17.69 14.33-32 32-32s32 14.31 32 32v70.23C122.1 64.58 186.1 32.11 256.1 32.11C379.5 32.11 480 132.6 480 256z" />
      </svg>
    </button>
  );
}
