import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import DOMPurify from 'dompurify';
import { enableES5 } from 'immer';

import 'core-js/stable';
import 'whatwg-fetch';
import './I18n';

import { getSessionId } from './_common/utils/session';
import { getDecodedToken } from './_common/utils/token';
import App from './app';

import './tailwind.css';

export const API_URL = `${process.env.API_HOST}:${process.env.API_PORT}${process.env.API_PATH}`;

enableES5();

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV_NAME,
    ignoreErrors: [/^ResizeObserver loop limit exceeded$/],
    release: process.env.VERSION,
  });

  Sentry.configureScope((scope) => {
    const user = getDecodedToken();

    if (user) {
      scope.setUser({
        id: user.id,
        sessionId: getSessionId(),
      });
    }
  });
}

// Inject React root element
document.body.innerHTML += '<div id="root"></div>';

// Render React app in the root element
const rootEl = document.getElementById('root');
if (rootEl) {
  render(
    <BrowserRouter>
      <div className="flex flex-col h-screen">
        <App />
      </div>
    </BrowserRouter>,
    rootEl,
  );
}
