import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import daAdmin from '../modules/admin/i18n/da.json';
import enAdmin from '../modules/admin/i18n/en.json';
import frAdmin from '../modules/admin/i18n/fr.json';
import nbAdmin from '../modules/admin/i18n/nb.json';
import nlAdmin from '../modules/admin/i18n/nl.json';
import daBooking from '../modules/booking/i18n/da.json';
import enBooking from '../modules/booking/i18n/en.json';
import frBooking from '../modules/booking/i18n/fr.json';
import nbBooking from '../modules/booking/i18n/nb.json';
import nlBooking from '../modules/booking/i18n/nl.json';
import daSelfservicing from '../modules/booking/i18n/selfservicing/da.json';
import enSelfservicing from '../modules/booking/i18n/selfservicing/en.json';
import frSelfservicing from '../modules/booking/i18n/selfservicing/fr.json';
import nbSelfservicing from '../modules/booking/i18n/selfservicing/nb.json';
import nlSelfservicing from '../modules/booking/i18n/selfservicing/nl.json';
import daConfirm from '../modules/confirm/i18n/da.json';
import enConfirm from '../modules/confirm/i18n/en.json';
import frConfirm from '../modules/confirm/i18n/fr.json';
import nbConfirm from '../modules/confirm/i18n/nb.json';
import nlConfirm from '../modules/confirm/i18n/nl.json';
import daLogin from '../modules/login/i18n/da.json';
import enLogin from '../modules/login/i18n/en.json';
import frLogin from '../modules/login/i18n/fr.json';
import nbLogin from '../modules/login/i18n/nb.json';
import nlLogin from '../modules/login/i18n/nl.json';
import daNotFound from '../modules/notFound/i18n/da.json';
import enNotFound from '../modules/notFound/i18n/en.json';
import frNotFound from '../modules/notFound/i18n/fr.json';
import nbNotFound from '../modules/notFound/i18n/nb.json';
import nlNotFound from '../modules/notFound/i18n/nl.json';
import daUser from '../modules/user/i18n/da.json';
import enUser from '../modules/user/i18n/en.json';
import frUser from '../modules/user/i18n/fr.json';
import nbUser from '../modules/user/i18n/nb.json';
import nlUser from '../modules/user/i18n/nl.json';

import daTranslation from './da.json';
import enTranslation from './en.json';
import frTranslation from './fr.json';
import nbTranslation from './nb.json';
import nlTranslation from './nl.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ['localStorage'], lookupLocalStorage: 'locale' },
    resources: {
      en: {
        translation: enTranslation,
        admin: enAdmin,
        booking: enBooking,
        selfservicing: enSelfservicing,
        notfound: enNotFound,
        login: enLogin,
        confirm: enConfirm,
        user: enUser,
      },
      da: {
        translation: daTranslation,
        admin: daAdmin,
        booking: daBooking,
        selfservicing: daSelfservicing,
        notfound: daNotFound,
        login: daLogin,
        confirm: daConfirm,
        user: daUser,
      },
      fr: {
        translation: frTranslation,
        admin: frAdmin,
        booking: frBooking,
        selfservicing: frSelfservicing,
        notfound: frNotFound,
        login: frLogin,
        confirm: frConfirm,
        user: frUser,
      },
      nb: {
        admin: nbAdmin,
        booking: nbBooking,
        selfservicing: nbSelfservicing,
        confirm: nbConfirm,
        login: nbLogin,
        notfound: nbNotFound,
        translation: nbTranslation,
        user: nbUser,
      },
      nl: {
        admin: nlAdmin,
        booking: nlBooking,
        selfservicing: nlSelfservicing,
        confirm: nlConfirm,
        login: nlLogin,
        notfound: nlNotFound,
        translation: nlTranslation,
        user: nlUser,
      },
    },
    preload: ['en'],
    fallbackLng: 'en',
    fallbackNS: ['translation', 'booking'],
    supportedLngs: ['en', 'da', 'fr', 'nb', 'nl'],
    nonExplicitSupportedLngs: true,
    returnEmptyString: false,
    parseMissingKeyHandler: () => '',
  });

export default i18n;
