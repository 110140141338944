import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeContext } from 'styled-components';

import { useCategoryContext } from '@common/context/CategoryContext';
import { BRAND_INFORMATION } from '@common/queries';
import { BrandInformationQuery } from '@typings/api';

interface Props {
  onClick: () => void;
}

export function InstanceLogo({ onClick }: Props) {
  const { instanceLogo: defaultInstanceLogo } = useContext(ThemeContext);
  const { category } = useCategoryContext();
  const { t } = useTranslation();

  const { data } = useQuery<BrandInformationQuery>(BRAND_INFORMATION, {
    variables: { categoryId: category?.id },
  });

  const logo = data?.brandInformation?.instanceLogo ?? defaultInstanceLogo;

  return (
    <button aria-label={t('translation:button.home')} onClick={onClick}>
      {logo != null && logo !== '' ? (
        <img alt="instance logo" className="h-8 cursor-pointer" src={logo} />
      ) : (
        <FontAwesomeIcon icon={['fas', 'home']} />
      )}
    </button>
  );
}
